import Header from "./Header";
import Form from "./Form";
import './Contact.css';

export default function Contact() {
    
    return (
        <>
            <Header />
            <Form />
        </>
    )
}