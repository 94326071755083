import Header from "./Header";
import Meet from "./Meet";
import Faith from "./Faith";
import Connect from "../Connect";
import './Home.css'

export default function Home() {

    return (
        <>
            <Header />
        </>
    )
}