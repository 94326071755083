import Header from './Header';
import Rate from './Rate';
import './Sessions.css';

export default function Sessions() {

    return (
        <>
            <Header />
            <Rate />
        </>
    )
}